import { useHooks } from "@/composables/useHooks";

declare let window: any;

export function useScrollEvent() {
    const hooks = useHooks();
    let lastEventParams: any = 0;
    let touchStart: any = null;
    let scrollStart: any = null;
    let timeout = 0;

    let wheelStartTop: any = 0;
    let scrollStartTop: any = 0;


    function setupScroll(scrollEl: any) {

        const signOfNumber = (num: number) => num > 0 ? 1 : num < 0 ? -1 : 0;

        const onScroll = (e: any, params: any) => {
            // e.stopPropagation();
            // lastEventParams = e;

            if (timeout) {
                window.cancelAnimationFrame(timeout);
            }

            timeout = window.requestAnimationFrame(() => {

                if (e.type == 'scroll') {
                    const offset = scrollEl.scrollTop - scrollStartTop;
                    const direction = signOfNumber(offset);

                    hooks.invokeHook('mousewheel', {
                        event: e,
                        direction: direction,
                        offset: offset,
                        params
                    })
                    scrollStartTop = scrollEl.scrollTop;
                }
                // if (e.type == 'wheel') {
                //     if (scrollEl.scrollTop == 0 ||
                //         scrollEl.scrollTop == scrollEl.scrollHeight - scrollEl.clientHeight)
                //         return e.preventDefault();

                //     const direction = scrollEl.scrollTop > wheelStartTop ? 1 : -1;
                //     console.log('wheel',direction);

                //     hooks.invokeHook('mousewheel', {
                //         event: e,
                //         direction: direction
                //     })

                //     wheelStartTop = scrollEl.scrollTop;
                // }

                if (e.type == 'touchmove') {
                    const offset = e?.changedTouches?.[0].screenY - touchStart?.changedTouches?.[0]?.screenY;
                    const direction = signOfNumber(offset);

                    if (Math.abs(offset) < 1) return;

                    hooks.invokeHook('mousewheel', {
                        event: e,
                        direction: lastEventParams == 0 ? 0 : direction,
                        offset: offset,
                        params
                    })

                    lastEventParams = e;
                }
            });
        };

        const onTouchStart = (e: any) => touchStart = e;
        const onScrollStart = (e: any) => scrollStart = e.target.scrollTop;

        // scrollEl.addEventListener("wheel", onScroll);    
        scrollEl.addEventListener("scroll", onScroll);
        scrollEl.addEventListener("touchmove", onScroll);
        scrollEl.addEventListener("touchstart", onTouchStart);
        scrollEl.addEventListener("mousedown", (e: any) => onScrollStart(e));
    }

    return {
        setupScroll,
        onScroll: (hook: any) => hooks.addHook('mousewheel', hook)
    }
}