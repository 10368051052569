import { findBook } from "./bibles-books.utils";
import { getVersesFromCacheOrLoad, preloadVerses } from "./bibles-chapters.utils";
import { locationToString, renderMultipleVersesHtml } from "./bibles-render.utils";
import { biblesColl, findBibleTransl } from "./bibles-transl.utils";
import { loading } from "./bibles.api";
import { defaultBible, defaultBook, defaultChapter } from "./defaults";

export function useBible(options?: any) {

    const versesColl = ref<any[]>([]);

    const location = reactive<any>({
        bibleModel: null,
        bookModel: null,
        chapterNum: 0,
        chapterNumIntoView: 0,
        versesHtml: '',
        // other
        endOfTheBook: computed(() => {
            const lastVerse = last(versesColl.value);
            return lastVerse?.chapterNum == lastVerse?.bookChapters;
        }),
        chapterTopNum: computed(() => first(versesColl.value)?.chapterNum),
        chapterBottomNum: computed(() => last(versesColl.value)?.chapterNum)
    })

    async function setLocation({ bibleCode, bookCode, chapterNum }: any = {}) {
        bibleCode = bibleCode || location.bibleModel?.bibleCode || defaultBible;
        bookCode = bookCode || location.bookModel?.bookCode || defaultBook;
        chapterNum = chapterNum || location?.chapterNum || defaultChapter;

        location.bibleModel = findBibleTransl({ bibleCode });
        location.bookModel = findBook({ bibleCode, bookCode });
        location.chapterNum = chapterNum;

        // render loaded verses, current chapter, prev and next
        const chapterPrevPromise = getVersesFromCacheOrLoad({ bibleCode, bookCode, chapterNum: chapterNum - 1 })
        const chapterCurrPromise = getVersesFromCacheOrLoad({ bibleCode, bookCode, chapterNum: chapterNum })
        const chapterNextPromise = getVersesFromCacheOrLoad({ bibleCode, bookCode, chapterNum: chapterNum + 1 })

        const [chapterPrev, chapterCurr, chapterNext] = await Promise.all([
            chapterPrevPromise, chapterCurrPromise, chapterNextPromise
        ])

        versesColl.value = [];
        if (chapterPrev) versesColl.value.push(...chapterPrev);
        if (chapterCurr) versesColl.value.push(...chapterCurr);
        if (chapterNext) versesColl.value.push(...chapterNext);

        location.versesHtml = renderMultipleVersesHtml(versesColl.value, { header: true })
        location.versesHashKey = Math.random();

        console.log('set: ', locationToString({ bibleCode, bookCode, chapterNum }))
    }


    async function showMoreTop({ scrollableEl }: any) {
        const topVerse = first(versesColl.value);

        if (!topVerse || topVerse?.chapterNum == 1) return;

        const nextChapter = await getVersesFromCacheOrLoad({
            bibleCode: topVerse.bibleCode,
            bookCode: topVerse.bookCode,
            chapterNum: topVerse.chapterNum - 1
        });
        if (!nextChapter) return;

        // Сохраняем текущую высоту контента
        const scrollTopPrev = scrollableEl.scrollTop;
        const currentContentHeight = scrollableEl.scrollHeight;

        // Добавляем новую главу в начало коллекции
        versesColl.value = [...nextChapter, ...versesColl.value];

        // Обновляем HTML и хэш-ключ для триггера перерисовки
        location.versesHtml = renderMultipleVersesHtml(versesColl.value, { header: true });

        // Ждем обновления DOM
        await nextTick();

        // Вычисляем высоту нового контента
        const newChapterHeight = scrollableEl.scrollHeight - currentContentHeight;

        // Корректируем позицию скролла
        scrollableEl.scrollTop = scrollTopPrev + newChapterHeight;
    }

    async function showMoreBottom() {

        // const bottomChapteversesColl.value.value);
        const bottomVerse = last(versesColl.value);

        // prevent overscroll bottom
        if (!bottomVerse || bottomVerse?.chapterNum == bottomVerse?.bookChapters) return;

        const nextChapter = await getVersesFromCacheOrLoad({
            bibleCode: bottomVerse.bibleCode,
            bookCode: bottomVerse.bookCode,
            chapterNum: bottomVerse.chapterNum + 1
        });

        if (!nextChapter) return;

        versesColl.value.push(...nextChapter);
        location.versesHashKey = Math.random();
        location.versesHtml = renderMultipleVersesHtml(versesColl.value, { header: true })
    }

    return {
        location,
        versesColl,
        biblesColl,

        setLocation,
        showMoreBottom,
        showMoreTop,

        loading,
        options
    }
}
