import { findBook } from "./bibles-books.utils";
import { findBibleTransl } from "./bibles-transl.utils";

function toAppBibleModel({ bible }: any = {}) {
    const bibleModel = {
        // _id: bible._id,
        bibleCode: bible.bible_code,
        bibleName: bible.bible_name,
        bibleShortName: bible.bible_short_name,
        bibleAuthor: bible.bible_author,
        bibleLocale: bible.bible_locale,
        bibleStrong: bible.bible_strong,
        bibleApps: bible.bible_apps,
        booksMap: {},
        versesMap: {}
    }

    return bibleModel;
}

function toAppBookModel({ book }: any = {}) {

    try {

        const bible = findBibleTransl({
            bibleCode: book.bible_code
        });;

        const bibleModel = {
            bibleCode: book.bible_code,
            bibleLocale: bible.bibleLocale,
            bookCode: book.book_code,
            bookPlace: book.book_place,
            bookName: book.book_name,
            bookNameCanonLong: book.book_name_canon_long,
            bookNameCanonShort: book.book_name_canon_short,
            bookIndex: book.book_index,
            bookChapters: book.book_chapters,
        }

        return bibleModel;
    }
    catch (e) {
        console.log(e)
    }
}

function toAppVerseModel({ verse }: any = {}) {

    const book = findBook({
        bibleCode: verse.bible_code,
        bookCode: verse.book_code
    });

    const verseModel = {
        _id: verse._id,
        bibleCode: verse.bible_code,
        bibleLocale: book.bibleLocale,
        bookCode: verse.book_code,
        bookCodeOrig: verse.book_code_orig,
        bookNameCanonLong: book.bookNameCanonLong,
        bookNameCanonShort: book.bookNameCanonShort,
        bookChapters: book.bookChapters,
        chapterNum: verse.chapter_num,
        verseNum: verse.verse_num,
        verseText: verse.verse_text,
    }

    return verseModel;
}

export {
    toAppBibleModel,
    toAppBookModel,
    toAppVerseModel
}